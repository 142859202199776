document.addEventListener('alpine:init', () => {
    Alpine.data('stockValueChart', (values, labels, ref) => ({
        values: [],
        labels: [],
        type: 'bar',
        color: '#fff',
        ref: ref,
        init() {
            this.values = JSON.parse(values)
            this.labels = JSON.parse(labels)
            const chart = new Chart(this.$refs[this.ref].getContext('2d'), {
                type: this.type,
                data: {
                    labels: this.labels,
                    datasets: this.values,
                },
                options: {
                    responsive:true,
                    maintainAspectRatio: false,
                    plugins: {
                        tooltip: {
                            mode: 'index',
                            intersect: false,
                            callbacks: {
                                label: function(tooltipItem) {
                                    // Haal het label en de waarde op
                                    const datasetLabel = tooltipItem.dataset.label || '';
                                    const value = tooltipItem.raw;
                                    const formattedValue = value.toLocaleString('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                                    return `${datasetLabel}: € ${formattedValue}`;
                                },
                            },
                        },
                    },
                    scales: {
                        y: {
                            stacked:true,
                            beginAtZero: true,
                        },
                        x: {
                            stacked:true,
                        }
                    },

                }
            });
        }
    }));
});
